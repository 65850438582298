import React, { useState } from "react"
import Layout from "../components/layout"
import "./contact.css"

import { Tooltip as ReactTooltip } from "react-tooltip"

function Contact() {
  const [copied, setCopied] = useState(false)
  const [burgerIsOpen, setBurgerIsOpen] = useState(false)
  return (
    <>
      <Layout burgerIsOpen={burgerIsOpen} setBurgerIsOpen={setBurgerIsOpen}>
        <div className="contact-container">
          <div className="contact-info">
            <ReactTooltip
              anchorId="anchor"
              place="bottom"
              content={copied ? "Copied" : "Copy"}
            />
            <h3
              id="anchor"
              onClick={() => {
                navigator.clipboard.writeText("Sam1Falconi@gmail.com")
                setCopied(true)
              }}
            >
              <h1>Feeling creative?</h1>
              Sam1Falconi@gmail.com
            </h3>
            <br />
            <h1>
              All cinematography featured on falconimedia.com is credited to Sam
              Falconi or Falconi Media Company.
            </h1>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
